import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import LayoutGalerieListeTirages from "../../../composants/layout/fr/LayoutGalerieListeTirages"

import { obtenirImages, obtenirPage } from "../../../js/client-es/utils"
import fragmentsImages from "../../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pageVenteTiragesGalaxies: allPagesCompilationYaml(
      filter: { code: { eq: "venteTiragesGalaxies" } }
    ) {
      ...FragmentPageYaml
    }
    imagesPaysage: allImagesCompilationYaml(
      filter: { code: { in: ["m101", "m94"] } }
    ) {
      ...FragmentImagePaysage
    }
    imagesPortrait: allImagesCompilationYaml(filter: { code: { eq: "smc" } }) {
      ...FragmentImagePortrait
    }
  }
`

export default function TiragesGalaxies() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pageVenteTiragesGalaxies")
  const images = obtenirImages(
    resultatsRequete,
    "imagesPaysage",
    "imagesPortrait"
  )

  return <LayoutGalerieListeTirages page={page} images={images} />
}
